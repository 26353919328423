.workflow-editor {
}

.workflow-editor-surface {
  width: 100%;
  height: calc(100vh - 340px);
  min-height: 700px;
}

#editor-checker-events-grid .ag-root .ag-header {
  display: none;
}
