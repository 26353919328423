.execution-events-grid {
  height: calc(100vh - 470px);
  min-height: 717px;
}

.execution-events-details-pane-right {
  position: relative;
  top: -10px;
  right: -5px;
}

#execution-events-grid .ag-root .ag-header {
  display: none;
}

/**
 * Markdown
 */

.execution-events-details {
}

.execution-events-details h1 {
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  margin: 0px;
}

.execution-events-details h2 {
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  margin: 0px;
}

.execution-events-details h3 {
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  margin: 0px;
}

.execution-events-details h4 {
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  margin: 0px;
}

.execution-events-details h5 {
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  margin: 0px;
}

.execution-events-details ol {
  margin: 0px;
}

.execution-events-details p {
  margin-bottom: 8px;
}

.execution-events-details p:last-child {
  margin-bottom: 0px;
}

.execution-events-details pre {
  background-color: #f9fcff;
  border-color: #d1d8ef;
  border-width: 1px;
  border-style: solid;
  padding-left: 6px;
  padding-right: 6px;
  margin-bottom: 8px;
  font-size: 11px;
  line-height: 16px;
  text-wrap: wrap;
}

.execution-events-details td {
  margin: 0px;
}

.execution-events-details th {
  padding: 2px;
}

.execution-events-details td {
  padding: 2px;
}

